@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Noto+Serif+TC:wght@200;300;400;500;600;700;900&family=Fahkwang:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@100..900&family=Italianno&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html, body {
  font-family: 'Noto Sans TC', 'sans-serif';
  -webkit-text-size-adjust: 100%; }

body {
  overflow-x: hidden; }

:focus-visible {
  outline: none; }

@layer utilities {
  @keyframes anim_clip {
    0% {
      clip-path: inset(0% 100% 0% 0%); }
    42%, 58% {
      clip-path: inset(0% 0% 0% 0%); }
    100% {
      clip-path: inset(0% 0% 0% 100%); } }
  .animate-clip {
    animation: anim_clip 0.7s both cubic-bezier(0.4, 0, 0.2, 1); }
  .animate-clip-classic {
    animation: anim_clip 1.2s both cubic-bezier(0.4, 0, 0.2, 1); }
  .basic-show {
    opacity: 0;
    pointer-events: none; }
    .basic-show.is-show {
      opacity: 1;
      pointer-events: all; }
  .basic-hover {
    cursor: pointer;
    transition: all .3s; }
    .basic-hover:hover {
      opacity: .6; }
  .writing-vertical-lr {
    writing-mode: vertical-lr; }
  .writing-vertical {
    writing-mode: vertical-rl; }
  .writing-unset {
    writing-mode: unset; }
  .img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .img-contain {
    width: 100%;
    height: 100%;
    object-fit: contain; }
  .img-fill {
    width: 100%;
    height: 100%;
    object-fit: fill; }
  .img-scaledown {
    width: 100%;
    height: 100%;
    object-fit: scale-down; }
  .tf {
    top: 50%;
    left: 50%;
    --tw-translate-y: -50%;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); }
  .tf-x {
    left: 50%;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); }
  .tf-y {
    top: 50%;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); }
  .tf-x-auto {
    left: auto;
    transform: none; }
  .tf-y-auto {
    top: auto;
    transform: none; }
  .tf-auto {
    top: 0%;
    left: 0%;
    transform: none; }
  .transform-none {
    transform: none; }
  .clip-0 {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); }
  .clip-x {
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%); }
  .zoom-10 {
    zoom: 0.1; }
  .zoom-15 {
    zoom: 0.15; }
  .zoom-20 {
    zoom: 0.2; }
  .zoom-25 {
    zoom: 0.25; }
  .zoom-30 {
    zoom: 0.3; }
  .zoom-35 {
    zoom: 0.35; }
  .zoom-40 {
    zoom: 0.4; }
  .zoom-45 {
    zoom: 0.45; }
  .zoom-50 {
    zoom: 0.5; }
  .zoom-55 {
    zoom: 0.55; }
  .zoom-60 {
    zoom: 0.6; }
  .zoom-65 {
    zoom: 0.65; }
  .zoom-70 {
    zoom: 0.7; }
  .zoom-75 {
    zoom: 0.75; }
  .zoom-80 {
    zoom: 0.8; }
  .zoom-85 {
    zoom: 0.85; }
  .zoom-90 {
    zoom: 0.9; }
  .zoom-95 {
    zoom: 0.95; }
  .zoom-100 {
    zoom: 1; }
  .zoom-105 {
    zoom: 1.05; }
  .zoom-110 {
    zoom: 1.1; }
  .zoom-115 {
    zoom: 1.15; }
  .zoom-120 {
    zoom: 1.2; }
  .zoom-125 {
    zoom: 1.25; }
  .zoom-130 {
    zoom: 1.3; }
  .zoom-135 {
    zoom: 1.35; }
  .zoom-140 {
    zoom: 1.4; }
  .zoom-145 {
    zoom: 1.45; }
  .zoom-150 {
    zoom: 1.5; }
  .zoom-155 {
    zoom: 1.55; } }

body {
  font-family: 'Fahkwang', 'sans-serif';
  overflow-x: hidden; }
  body.is-lock {
    margin-right: 17px;
    overflow: hidden; }

@screen lg {
  body.is-lock {
    margin-right: 0; } }

#topmenuWrap.is-hide {
  opacity: 0; }

.latestSpace-items .latest-slider {
  clip-path: polygon(87% 0, 100% 83%, 52.2% 83%, 50% 67%, 45.5% 100%, 0 100%, 15% 0); }

@screen lg {
  .latestSpace-items .latest-slider {
    clip-path: polygon(50% -200%, 250% 140%, 56% 140%, 49% 120%, 38% 135%, -160% 135%, 50% -200%); } }
  .latestSpace-items .latest-slider li {
    pointer-events: none !important; }

.index-fixed-logo {
  position: fixed;
  left: calc(50% + 19px);
  transform: translateX(-50%);
  top: -29px;
  transition: all 2s; }

@screen lg {
  .index-fixed-logo {
    left: 50%; } }
  .index-fixed-logo .logo {
    transition: all 2s; }
  .index-fixed-logo.is-small .logo {
    transform: scale(0.28) translate(-67px, 2px); }

@screen lg {
  .index-fixed-logo.is-small .logo {
    transform: scale(0.42) translate(-3px, 36px); } }
  .index-fixed-logo.is-lock {
    top: 33.4vh; }

.index-sun {
  width: 2186px;
  height: 1634px;
  background: linear-gradient(180deg, #ffe0ab 0%, white 50%);
  box-shadow: 0 0 60px #ffe0ab;
  border-radius: 50% 50% 0 0; }

.menuOpen .close {
  color: #fff;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 44px;
  font-size: 12px;
  letter-spacing: 1px;
  opacity: 0;
  transition: all .5s;
  pointer-events: none; }

.menuOpen.is-white > ul > li {
  background-color: #fff; }
  .menuOpen.is-white > ul > li:after {
    background-color: #fff; }

.menuOpen.is-white-fixed > ul > li {
  background-color: #fff !important; }
  .menuOpen.is-white-fixed > ul > li:after {
    background-color: #fff !important; }

.menuOpen.is-open .close {
  opacity: 1;
  pointer-events: auto; }

.menuOpen.is-open > ul > li:nth-child(1) {
  transform: translateY(11px); }

.menuOpen.is-open > ul > li:nth-child(3) {
  transform: translateY(-11px); }

.menuOpen.is-hide {
  top: -140px; }

.menuOpen > ul > li {
  transition: all .5s;
  position: relative;
  display: block;
  width: 26px;
  height: 3px;
  background-color: #353434; }
  .menuOpen > ul > li:after {
    position: absolute;
    content: "";
    width: 12px;
    height: 2px;
    background-color: #353434;
    bottom: -2px;
    right: -12px; }

.time-area .item {
  overflow: hidden;
  width: 200px;
  height: 250px; }

@screen lg {
  .time-area .item {
    width: 46px;
    height: 58px; } }
  .time-area .item span {
    display: block; }

.select-mod .nice-select {
  font-family: 'Noto Sans TC', 'sans-serif';
  width: 100%;
  float: inherit;
  height: 64px;
  line-height: 64px;
  font-weight: 500;
  border: 1px solid #333333;
  border-radius: 0;
  font-size: 16px; }

@screen lg {
  .select-mod .nice-select {
    height: 40px;
    line-height: 40px;
    font-size: 13px; } }
  .select-mod .nice-select.open:after {
    transform: rotate(-180deg);
    margin-top: -20px;
    right: 18px; }

@screen lg {
  .select-mod .nice-select.open:after {
    margin-top: -10px;
    right: 16px; } }
  .select-mod .nice-select:after {
    background: url("../images/project-arrow.svg") 0 0/100% 100% no-repeat;
    width: 21px;
    height: 25px;
    transform: rotate(0deg);
    border: 0;
    margin-top: -10px; }

@screen lg {
  .select-mod .nice-select:after {
    width: 10px;
    height: 12px;
    margin-top: -5px; } }
  .select-mod .nice-select .list {
    width: 100%;
    background-color: #666666;
    border-radius: 0px 0px 24px 24px;
    color: white;
    margin-top: 1px; }
  .select-mod .nice-select .option {
    padding: 12px 18px;
    border-bottom: 1px solid #fff; }

@screen lg {
  .select-mod .nice-select .option {
    padding: 6px 18px; } }
    .select-mod .nice-select .option:last-child {
      border-bottom: 0; }
    .select-mod .nice-select .option:not(:hover) {
      background-color: #666 !important; }
    .select-mod .nice-select .option:hover {
      color: #666;
      font-weight: 400; }

#projectLogin input {
  -webkit-appearance: none;
  border-radius: 0;
  height: 64px;
  line-height: 64px;
  border: 1px solid #333333;
  padding-left: 18px;
  padding-right: 30px; }

@screen lg {
  #projectLogin input {
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    border: 1px solid #333333; } }

@screen lg {
  #projectLogin .nice-select {
    border: 1px solid #333333; } }

#serviceLogin input, #latestForm input {
  -webkit-appearance: none;
  border-radius: 0;
  background: transparent;
  width: 100%;
  height: 36px; }

@screen lg {
  #serviceLogin input, #latestForm input {
    height: 24px; } }

#serviceLogin .radio-group, #latestForm .radio-group {
  /*偽元素式*/ }
  #serviceLogin .radio-group input[type='radio'], #latestForm .radio-group input[type='radio'] {
    display: none; }
  #serviceLogin .radio-group input[type='radio'] + label, #latestForm .radio-group input[type='radio'] + label {
    font-family: 'Noto Sans TC', 'sans-serif';
    font-size: 18px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding-left: 42px;
    cursor: pointer; }

@screen lg {
  #serviceLogin .radio-group input[type='radio'] + label, #latestForm .radio-group input[type='radio'] + label {
    font-size: 1rem;
    padding-left: 32px; } }
    #serviceLogin .radio-group input[type='radio'] + label:before, #latestForm .radio-group input[type='radio'] + label:before {
      content: '';
      display: block;
      width: 28px;
      height: 28px;
      border: 1px solid #333333;
      margin-right: 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      border-radius: 50%; }

@screen lg {
  #serviceLogin .radio-group input[type='radio'] + label:before, #latestForm .radio-group input[type='radio'] + label:before {
    width: 22px;
    height: 22px; } }
  #serviceLogin .radio-group input[type='radio']:checked + label:after, #latestForm .radio-group input[type='radio']:checked + label:after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background-color: #333333;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    border-radius: 50%; }

@screen lg {
  #serviceLogin .radio-group input[type='radio']:checked + label:after, #latestForm .radio-group input[type='radio']:checked + label:after {
    width: 10px;
    height: 10px;
    left: 6px; } }
  #serviceLogin .radio-group.black input[type='radio'] + label:before, #latestForm .radio-group.black input[type='radio'] + label:before {
    border: 1px solid #333333; }
  #serviceLogin .radio-group.black input[type='radio']:checked + label:after, #latestForm .radio-group.black input[type='radio']:checked + label:after {
    background-color: #333333; }
  #serviceLogin .radio-group.white input[type='radio'] + label:before, #latestForm .radio-group.white input[type='radio'] + label:before {
    border: 1px solid #ffffff; }
  #serviceLogin .radio-group.white input[type='radio']:checked + label:after, #latestForm .radio-group.white input[type='radio']:checked + label:after {
    background-color: #ffffff; }

#serviceLogin textarea, #latestForm textarea {
  -webkit-appearance: none;
  border-radius: 0;
  background: transparent;
  width: 100%;
  height: 130px; }

@screen lg {
  #serviceLogin textarea, #latestForm textarea {
    height: 120px; } }

#serviceLogin .item .title, #latestForm .item .title {
  position: relative; }
  #serviceLogin .item .title.white:before, #latestForm .item .title.white:before {
    color: #fff; }
  #serviceLogin .item .title:before, #latestForm .item .title:before {
    position: absolute;
    content: "*";
    font-family: 'Fahkwang', 'sans-serif';
    font-size: 22px;
    color: #666666;
    top: -2px;
    left: -20px; }

@screen lg {
  #serviceLogin .item .title:before, #latestForm .item .title:before {
    font-size: 14px;
    top: 0;
    left: -10px; } }

.sliderbox {
  transform: translateY(100%);
  transition: transform 1s cubic-bezier(0.4, 0.4, 0.1, 1); }

@screen lg {
  .sliderbox {
    height: calc(var(--vh, 1vh) * 90); } }
  .sliderbox.is-open {
    transform: translateY(0); }
  .sliderbox .swiper-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative; }
    .sliderbox .swiper-container .swiper-wrapper {
      display: flex;
      transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) !important; }
    .sliderbox .swiper-container .swiper-slide {
      height: 100%;
      width: auto; }

@screen lg {
  .sliderbox .swiper-container .swiper-slide {
    width: 100%; } }
  .sliderbox .swiper-scrollbar {
    width: 100%;
    height: 1px;
    background: #B3B3B3 !important;
    position: static !important; }
    .sliderbox .swiper-scrollbar .swiper-scrollbar-drag {
      height: 3px;
      top: -1px;
      transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
      background: #B3B3B3 !important; }

.sliderbox-news .swiper-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative; }
  .sliderbox-news .swiper-container .swiper-wrapper {
    display: flex; }
  .sliderbox-news .swiper-container .swiper-slide {
    height: 100%;
    width: auto; }

.sliderbox-news .swiper-scrollbar {
  width: 100%;
  height: 1px;
  background: #B3B3B3 !important;
  position: static !important; }
  .sliderbox-news .swiper-scrollbar .swiper-scrollbar-drag {
    height: 3px;
    top: -1px;
    background: #B3B3B3 !important; }

.news-pager a {
  color: #888888;
  position: relative; }
  .news-pager a:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -2px;
    transform: translateX(-50%);
    width: 12px;
    height: 2px;
    background-color: #000000;
    opacity: 0; }
  .news-pager a.current {
    color: #333333; }
    .news-pager a.current:after {
      opacity: 1; }

.project-year .nice-select {
  float: none;
  width: 190px;
  border-radius: 50px;
  border: 1px solid #888888;
  height: 68px;
  line-height: 64px;
  font-size: 18px;
  padding-left: 26px;
  padding-right: 64px; }

@screen lg {
  .project-year .nice-select {
    width: 120px;
    height: 54px;
    line-height: 50px;
    padding-left: 16px;
    padding-right: 30px; } }
  .project-year .nice-select.open {
    background: #333333; }
    .project-year .nice-select.open:after {
      background: url("../images/projectall-arrow-white.svg") 0 0/100% 100% no-repeat;
      transform: rotate(-180deg);
      margin-top: -12px;
      right: 28px; }

@screen lg {
  .project-year .nice-select.open:after {
    right: 18px; } }
    .project-year .nice-select.open .current {
      position: relative;
      color: #fff;
      display: block; }
      .project-year .nice-select.open .current:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        background-color: #fff;
        left: 0;
        bottom: 16px; }
  .project-year .nice-select:after {
    background: url("../images/projectall-arrow.svg") 0 0/100% 100% no-repeat;
    width: 12px;
    height: 14px;
    transform: rotate(0deg);
    border: 0;
    margin-top: -6px;
    right: 24px; }

@screen lg {
  .project-year .nice-select:after {
    width: 10px;
    height: 12px;
    right: 18px; } }
  .project-year .nice-select .list {
    font-family: 'Noto Sans TC', 'sans-serif';
    font-size: 16px;
    padding-top: 32px;
    padding-bottom: 10px;
    top: 32px;
    width: 100%;
    border-radius: 0 0 34px 34px;
    box-shadow: 0 0 0 1px #888888;
    z-index: -1;
    @screen xl {} }

@screen lg {
  .project-year .nice-select .list {
    top: 24px;
    padding-top: 24px;
    font-size: 14px; } }
    .project-year .nice-select .list li {
      position: relative;
      padding: 0px 26px; }
      .project-year .nice-select .list li:after {
        position: absolute;
        content: "";
        width: calc(100% - 18px);
        bottom: 0;
        height: 1px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(128, 128, 128, 0.25); }
      .project-year .nice-select .list li:last-child:after {
        content: none; }

.projectAllWrap article .head-list {
  grid-template-columns: 1fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.7fr;
  grid-gap: 0 60px; }

@screen lg {
  .projectAllWrap article .head-list {
    grid-gap: 0 20px; } }

@screen lg {
  .projectAllWrap article .head-list li {
    min-width: 100px;
    max-width: 100px; }
    .projectAllWrap article .head-list li:nth-child(6) {
      min-width: 140px;
      max-width: 140px; } }

.projectAllWrap article .projectAll-items {
  grid-template-columns: 1fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.7fr;
  grid-gap: 0 60px;
  min-height: 95px; }

@screen lg {
  .projectAllWrap article .projectAll-items {
    grid-gap: 0 20px;
    min-height: auto; } }

@screen lg {
  .projectAllWrap article .projectAll-items li {
    min-width: 100px;
    max-width: 100px; }
    .projectAllWrap article .projectAll-items li:nth-child(6) {
      min-width: 140px;
      max-width: 140px; } }

.newsInformation {
  grid-template-columns: 1fr 0.7fr 0.7fr; }

.differentList li {
  position: relative; }
  .differentList li .progress-box {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0; }
    .differentList li .progress-box .line {
      background-color: #B0B0B0; }
    .differentList li .progress-box .progress-bar {
      background-color: #4D4D4D; }

.corporationList > div {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }

.asset-selectWrap .nice-select {
  width: 175px;
  border-radius: 50px;
  border: 1px solid #888888;
  height: 68px;
  line-height: 64px;
  font-size: 18px;
  padding-left: 26px;
  padding-right: 64px; }

@screen lg {
  .asset-selectWrap .nice-select {
    font-size: 14px;
    width: 98px;
    height: 40px;
    line-height: 38px;
    padding-left: 14px;
    padding-right: 24px; } }
  .asset-selectWrap .nice-select.open {
    background: #333333; }
    .asset-selectWrap .nice-select.open:after {
      background: url("../images/projectall-arrow-white.svg") 0 0/100% 100% no-repeat;
      transform: rotate(-180deg);
      margin-top: -12px;
      right: 28px; }

@screen lg {
  .asset-selectWrap .nice-select.open:after {
    right: 16px; } }
    .asset-selectWrap .nice-select.open .current {
      position: relative;
      color: #fff;
      display: block; }
      .asset-selectWrap .nice-select.open .current:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        background-color: #fff;
        left: 0;
        bottom: 16px; }

@screen lg {
  .asset-selectWrap .nice-select.open .current:before {
    height: 1px;
    bottom: 8px; } }
  .asset-selectWrap .nice-select:after {
    background: url("../images/projectall-arrow.svg") 0 0/100% 100% no-repeat;
    width: 12px;
    height: 14px;
    transform: rotate(0deg);
    border: 0;
    margin-top: -6px;
    right: 24px; }

@screen lg {
  .asset-selectWrap .nice-select:after {
    width: 10px;
    height: 12px;
    right: 12px; } }
  .asset-selectWrap .nice-select .list {
    color: #808080;
    font-family: 'Noto Sans TC', 'sans-serif';
    z-index: -1;
    font-size: 16px;
    padding-top: 28px;
    padding-bottom: 10px;
    top: 36px;
    width: 100%;
    border-radius: 0 0 34px 34px;
    box-shadow: 0 0 0 1px #888888; }

@screen lg {
  .asset-selectWrap .nice-select .list {
    border-radius: 0 0 24px 24px;
    top: 16px;
    padding-top: 16px; } }
    .asset-selectWrap .nice-select .list li {
      position: relative;
      padding: 0px 26px; }

@screen lg {
  .asset-selectWrap .nice-select .list li {
    padding: 0px 12px; } }
      .asset-selectWrap .nice-select .list li:after {
        position: absolute;
        content: "";
        width: calc(100% - 18px);
        bottom: 0;
        height: 1px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(128, 128, 128, 0.25); }
      .asset-selectWrap .nice-select .list li:last-child:after {
        content: none; }

.assetList > li:nth-child(2n-1) {
  border-right: 1px solid #80808040; }

@screen lg {
  .assetList > li:nth-child(2n-1) {
    border: 0; } }

#startList > li:not(:first-child) {
  clip-path: polygon(125% 0%, 100% 0%, 100% 100%, 100% 100%); }

#differentList-pics > li:not(:first-child) {
  clip-path: polygon(125% 0%, 100% 0%, 100% 100%, 100% 100%); }

.man-pic {
  clip-path: polygon(-5% 0%, 100% 0%, 100% 100%, -20% 100%); }

@keyframes scroll-y-hint {
  0% {
    left: 10%; }
  45.99% {
    left: 80%;
    opacity: 0; }
  46% {
    left: 0%;
    opacity: 0; }
  to {
    left: 10%;
    opacity: 1; } }

@keyframes viewmore-arrow {
  0% {
    transform: translateX(0%); }
  45.99% {
    transform: translateX(125%);
    opacity: 0; }
  46% {
    transform: translateX(-125%);
    opacity: 0; }
  to {
    transform: translateX(0%);
    opacity: 1; } }

.submit:hover .submit-deco g {
  animation: viewmore-arrow .5s; }

@keyframes go-scroll {
  0% {
    transform: translateY(0%); }
  45.99% {
    transform: translateY(125%);
    opacity: 0; }
  46% {
    transform: translateY(-125%);
    opacity: 0; }
  to {
    transform: translateY(0%);
    opacity: 1; } }

.go-scroll svg {
  animation: go-scroll 1.5s infinite; }

@screen lg {
  .go-scroll.corporation {
    top: calc(var(--vh, 1vh) * 65); } }

@keyframes go-top {
  0% {
    transform: translateY(0px); }
  45.99% {
    transform: translateY(-15px);
    opacity: 0; }
  46% {
    transform: translateY(15px);
    opacity: 0; }
  to {
    transform: translateY(0px);
    opacity: 1; } }

#backtotop:hover svg {
  animation: go-top 1.5s infinite; }

@keyframes go-back {
  0% {
    transform: translateX(0px); }
  45.99% {
    transform: translateX(-10px);
    opacity: 0; }
  46% {
    transform: translateX(10px);
    opacity: 0; }
  to {
    transform: translateX(0px);
    opacity: 1; } }

@keyframes go-more {
  0% {
    transform: translateX(0px); }
  45.99% {
    transform: translateX(10px);
    opacity: 0; }
  46% {
    transform: translateX(-10px);
    opacity: 0; }
  to {
    transform: translateX(0px);
    opacity: 1; } }

.go-back {
  cursor: pointer; }
  .go-back:hover polygon {
    animation: go-back 1.5s infinite; }

.go-more {
  cursor: pointer; }
  .go-more:hover polygon {
    animation: go-more 1.5s infinite; }
  .go-more:hover g {
    animation: go-more 1.5s infinite; }

.latest-prev {
  cursor: pointer; }
  .latest-prev:hover .switch-prev {
    animation: go-back 1.5s infinite; }

.latest-next {
  cursor: pointer; }
  .latest-next:hover .switch-next {
    animation: go-more 1.5s infinite; }

.scroll-y-hint {
  animation: scroll-y-hint 2.5s infinite; }

@screen lg {
  .heads-mobile {
    top: calc(var(--vh, 1vh) * 73); }
    .heads-mobile.is-go {
      top: 73vh; } }

@screen lg {
  #classic-start {
    height: calc(var(--vh, 1vh) * 100); } }

@screen lg {
  .pics-mobile {
    height: calc(var(--vh, 1vh) * 50); } }

@screen lg {
  #picsList {
    height: calc(var(--vh, 1vh) * 50); } }

@screen lg {
  .pics-mask {
    height: calc(var(--vh, 1vh) * 50); } }

@screen lg {
  .house {
    height: calc(var(--vh, 1vh) * 50); } }

@screen lg {
  #houseList {
    margin-top: calc(var(--vh, 1vh) * 20); } }

#mobile-more {
  margin-top: calc(var(--vh, 1vh) * 22); }

@screen lg {
  .home_hgroup {
    top: calc(var(--vh, 1vh) * -8);
    transform: none; }
    .home_hgroup.is-go {
      top: calc(var(--vh, 1vh) * -1 + 4px); } }
