@tailwind base;
@tailwind components;
@tailwind utilities;

@import "basic";


:root{
	// --cmsLinkColor: theme('colors.brown.DEFAULT');
}

// clip-path: polygon(60% 0, 100% 80%, 60% 80%, 52% 65%, 35% 100%, 0 100%, 50% 0);

body{
	font-family: 'Fahkwang', 'sans-serif';
	overflow-x: hidden;
	&.is-lock {
		margin-right: 17px;
		overflow: hidden;
		@screen lg{
			margin-right: 0;
		}
	}
}
#topmenuWrap{
	&.is-hide{
		opacity: 0;
	}
}

.latestSpace-items{
	.latest-slider{
		clip-path: polygon(87% 0, 100% 83%, 52.2% 83%, 50% 67%, 45.5% 100%, 0 100%, 15% 0);
		@screen lg{
			clip-path: polygon(50% -200%, 250% 140%, 56% 140%, 49% 120%, 38% 135%, -160% 135%, 50% -200%);
		}
		li{
			pointer-events: none !important;
		}
	}
}
.index-fixed-ani{

}
.index-fixed-logo{
    position: fixed;
    left: calc(50% + 19px);
    transform: translateX(-50%);
    top: -29px;
    transition: all 2s;
    @screen lg{
    	left: 50%;
    }
    .logo{
    	transition: all 2s;
    }
    &.is-small{
    	.logo{
    		transform: scale(0.28) translate(-67px, 2px);
    		@screen lg{
			    transform: scale(0.42) translate(-3px, 36px);
    		}
    	}
    }
    &.is-lock{
    	top: 33.4vh;
    	.logo{
    	}
    }
}
.index-sun{
	width: 2186px;
	height: 1634px;
    background: linear-gradient(180deg, #ffe0ab 0%, white 50%);
	box-shadow: 0 0 60px #ffe0ab;
    border-radius: 50% 50% 0 0;
}
.menuOpen{
	.close{
		color: #fff;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		padding-right: 44px;
		font-size: 12px;
	    letter-spacing: 1px;
	    opacity: 0;
	    transition: all .5s;
	    pointer-events: none;
	}
	&.is-white{
		>ul{
			>li{
				background-color: #fff;
				&:after{
					background-color: #fff;
				}
			}
		}
	}
	&.is-white-fixed{
		>ul{
			>li{
				background-color: #fff !important;
				&:after{
					background-color: #fff !important;
				}
			}
		}
	}
	&.is-open{
		.close{
			opacity: 1;
			pointer-events: auto;
		}
		>ul{
			>li{
				&:nth-child(1){
				    transform: translateY(11px);
				}
				&:nth-child(3){
				    transform: translateY(-11px);
				}
			}
		}
	}
	&.is-hide{
		top: -140px;
	}
	>ul{
		>li{
			transition: all .5s;
			position: relative;
			display: block;
			width: 26px;
			height: 3px;
			background-color: #353434;
			&:after{
				position: absolute;
				content: "";
				width: 12px;
				height: 2px;
				background-color: #353434;
			    bottom: -2px;
    			right: -12px;
			}
		}
	}
}
.time-area{
	.item{
		overflow: hidden;
		width: 200px;
    	height: 250px;
    	@screen lg{
    		width: 46px;
    		height: 58px;
    	}
		span{
			display: block;
		}
	}
}
.select-mod{
	.nice-select{
	    font-family: 'Noto Sans TC', 'sans-serif';
		width: 100%;
		float: inherit;
		height: 64px;
		line-height: 64px;
		font-weight: 500;
		border: 1px solid #333333;
		border-radius: 0;
		font-size: 16px;
		@screen lg{
			height: 40px;
			line-height: 40px;
			font-size: 13px;
		}
		&.open{
			&:after{
				transform: rotate(-180deg);
			    margin-top: -20px;
			    right: 18px;
			    @screen lg{
			    	margin-top: -10px;
				    right: 16px;
				}
			}
		}
		&:after{
		    background: url('../images/project-arrow.svg') 0 0 / 100% 100% no-repeat;
		    width: 21px;
		    height: 25px;
		    transform: rotate(0deg);
		    border: 0;
		    margin-top: -10px;
		    @screen lg{
		    	width: 10px;
		    	height: 12px;
		    	margin-top: -5px;
		    }
		}
		.list{
			width: 100%;
			background-color: #666666;
		    border-radius: 0px 0px 24px 24px;
		    color: white;
		    margin-top: 1px;
		}
		.option{
			padding: 12px 18px;
			border-bottom: 1px solid #fff;
			@screen lg{
				padding: 6px 18px;
			}
			&:last-child{
				border-bottom: 0;
			}
			&:not(:hover){
				background-color: #666 !important;
			}
			&:hover{
			    color: #666;
    			font-weight: 400;
			}
		}
	}
}
#projectLogin{
	input{
		-webkit-appearance: none; 
		border-radius: 0;
		height: 64px;
		line-height: 64px;
		border: 1px solid #333333;
	    padding-left: 18px;
    	padding-right: 30px;
    	@screen lg{
			height: 40px;
			line-height: 40px;
			font-size: 13px;
			border: 1px solid #333333;
		}
	}
	.nice-select{
		@screen lg{
			border: 1px solid #333333;
		}
	}
}
#serviceLogin, #latestForm{
	input{
		-webkit-appearance: none; 
		border-radius: 0;
		background: transparent;
		width: 100%;
		height: 36px;
		@screen lg{
			height: 24px;
		}
	}
	.radio-group{
		/*偽元素式*/
		input[type='radio']{
			display: none;
		}
		input[type='radio'] + label{
			font-family: 'Noto Sans TC', 'sans-serif';
			font-size: 18px;
			position: relative;
			display: inline-block;
			vertical-align: top;
			padding-left: 42px;
			cursor: pointer;
			@screen lg{
				font-size: 1rem;
				padding-left: 32px;
			}
			&:before{
				content: '';
				display: block;
				width: 28px;
				height: 28px;
				border: 1px solid #333333;
				margin-right: 10px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				border-radius: 50%;
				@screen lg{
					width: 22px;
					height: 22px;
				}
			}
		}
		input[type='radio']:checked + label{
			&:after{
				content: '';
				display: block;
				width: 18px;
				height: 18px;
				background-color: #333333;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 5px;
				border-radius: 50%;
				@screen lg{
					width: 10px;
					height: 10px;
					left: 6px;
				}
			}
		}
		&.black{
			input[type='radio'] + label{
				&:before{
					border: 1px solid #333333;
				}
			}
			input[type='radio']:checked + label{
				&:after{
					background-color: #333333;
				}
			}
		}
		&.white{
			input[type='radio'] + label{
				&:before{
					border: 1px solid #ffffff;
				}
			}
			input[type='radio']:checked + label{
				&:after{
					background-color: #ffffff;
				}
			}
		}
	}
	textarea{
		-webkit-appearance: none; 
		border-radius: 0;
		background: transparent;
		width: 100%;
		height: 130px;
		@screen lg{
			height: 120px;
		}
	}
	.item{
		.title{
			position: relative;
			&.white{
				&:before{
				    color: #fff;
				}
			}
			&:before{
				position: absolute;
			    content: "*";
			    font-family: 'Fahkwang', 'sans-serif';
			    font-size: 22px;
			    color: #666666;
			    top: -2px;
			    left: -20px;
			    @screen lg{
			    	font-size: 14px;
			    	top: 0;
			    	left: -10px;
			    }
			}
		}
	}
}
.sliderbox{
	transform: translateY(100%);
    transition: transform 1s cubic-bezier(.4,.4,.1,1);
    @screen lg{
		height: calc(var(--vh, 1vh) * 90);
	}
	&.is-open{
		transform: translateY(0);
	}
	.swiper-container{
		width: 100%;
		height: 100%;
		display: flex;
		position: relative;
		
		.swiper-wrapper{
			display: flex;
			transition: transform .8s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
		}
		.swiper-slide{
			height: 100%;
			width: auto;
			@screen lg{
				width: 100%;
			}
		}
	}
	.swiper-scrollbar{
		width: 100%;
		height: 1px;
		background: #B3B3B3 !important;
		position: static !important;
		.swiper-scrollbar-drag{
			height: 3px;
			top: -1px;
			transition: transform .5s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
			background: #B3B3B3 !important;
		}
	}
}
.sliderbox-news{
	.swiper-container{
		width: 100%;
		height: 100%;
		display: flex;
		position: relative;
		
		.swiper-wrapper{
			display: flex;
		}
		.swiper-slide{
			height: 100%;
			width: auto;
		}
	}
	.swiper-scrollbar{
		width: 100%;
		height: 1px;
		background: #B3B3B3 !important;
		position: static !important;
		.swiper-scrollbar-drag{
			height: 3px;
			top: -1px;
			background: #B3B3B3 !important;
		}
	}
}
.news-pager{
	a{
		color: #888888;
		position: relative;
		&:after{
			position: absolute;
			content: "";
			left: 50%;
			bottom: -2px;
			transform: translateX(-50%);
			width: 12px;
			height: 2px;
			background-color: #000000;
			opacity: 0;
		}
		&.current{
			color: #333333;
			&:after{
				opacity: 1;
			}
		}
	}
}
.project-year{
	.nice-select{
		float: none;
		width: 190px;
		border-radius: 50px;
		border: 1px solid #888888;
		height: 68px;
		line-height: 64px;
		font-size: 18px;
		padding-left: 26px;
		padding-right: 64px;
		@screen lg{
			width: 120px;
		    height: 54px;
		    line-height: 50px;
		    padding-left: 16px;
		    padding-right: 30px;
		}
		&.open{
		    background: #333333;
		    &:after{
		    	background: url('../images/projectall-arrow-white.svg') 0 0 / 100% 100% no-repeat;
				transform: rotate(-180deg);
			    margin-top: -12px;
			    right: 28px;
			    @screen lg{
			    	right: 18px;
			    }
			}
    		.current{
    			position: relative;
    			color: #fff;
    			display: block;
    			&:before{
    				position: absolute;
    				content: "";
    				width: 100%;
    				height: 2px;
    				background-color: #fff;
    				left: 0;
    				bottom: 16px;
    			}
    		}
		}
		&:after{
		    background: url('../images/projectall-arrow.svg') 0 0 / 100% 100% no-repeat;
		    width: 12px;
		    height: 14px;
		    transform: rotate(0deg);
		    border: 0;
		    margin-top: -6px;
		    right: 24px;
		    @screen lg{
		    	width: 10px;
		    	height: 12px;
		    	right: 18px;
		    }
		}
		.list{
			font-family: 'Noto Sans TC', 'sans-serif';
			font-size: 16px;
		    padding-top: 32px;
	        padding-bottom: 10px;
    		top: 32px;
			width: 100%;
			border-radius: 0 0 34px 34px;
		    box-shadow: 0 0 0 1px #888888;
			z-index: -1;
			@screen xl{
			}
			@screen lg{
				top: 24px;
				padding-top: 24px;
				font-size: 14px;
			}
			li{
				position: relative;
			    padding: 0px 26px;
				&:after{
					position: absolute;
					content: "";
					width: calc(100% - 18px);
					bottom: 0;
					height: 1px;
					left: 50%;
					transform: translateX(-50%);
					background-color: rgba(#808080, .25);
				}
				&:last-child{
					&:after{
						content: none;
					}
				}
			}
		}
	}
}
.projectAllWrap{
	article{
		.head-list{
			grid-template-columns: 1fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.7fr;
		    grid-gap: 0 60px;
		    @screen lg{
		    	grid-gap: 0 20px;
		    }
			li{
				@screen lg{
					min-width: 100px;
					max-width: 100px;
					&:nth-child(6){
						min-width: 140px;
						max-width: 140px;
					}
				}
			}
		}
		.projectAll-items{
			grid-template-columns: 1fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.7fr;
		    grid-gap: 0 60px;
	        min-height: 95px;
	        @screen lg{
	        	grid-gap: 0 20px;
        	    min-height: auto;
	        }
			li{
				@screen lg{
					min-width: 100px;
					max-width: 100px;
					&:nth-child(6){
						min-width: 140px;
						max-width: 140px;
					}
				}
			}
		}
	}
}
.newsInformation{
	grid-template-columns: 1fr 0.7fr 0.7fr;
}
.differentList{
	li{
		position: relative;
		.progress-box{
			position: absolute;
			width: 100%;
			bottom: 0;
			left: 0;
			.line{
				background-color: #B0B0B0;
			}
			.progress-bar{
				background-color: #4D4D4D;
			}
		}
	}
}
.corporationList{
	>div{
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
}
.asset-selectWrap{
	.nice-select{
		width: 175px;
		border-radius: 50px;
		border: 1px solid #888888;
		height: 68px;
		line-height: 64px;
		font-size: 18px;
		padding-left: 26px;
		padding-right: 64px;
		@screen lg{
			font-size: 14px;
			width: 98px;
			height: 40px;
			line-height: 38px;
			padding-left: 14px;
			padding-right: 24px;
		}
		&.open{
		    background: #333333;
		    &:after{
		    	background: url('../images/projectall-arrow-white.svg') 0 0 / 100% 100% no-repeat;
				transform: rotate(-180deg);
			    margin-top: -12px;
			    right: 28px;
			    @screen lg{
			    	right: 16px;
			    }
			}
    		.current{
    			position: relative;
    			color: #fff;
    			display: block;
    			&:before{
    				position: absolute;
    				content: "";
    				width: 100%;
    				height: 2px;
    				background-color: #fff;
    				left: 0;
    				bottom: 16px;
    				@screen lg{
    					height: 1px;
    					bottom: 8px;
					}
    			}
    		}
		}
		&:after{
		    background: url('../images/projectall-arrow.svg') 0 0 / 100% 100% no-repeat;
		    width: 12px;
		    height: 14px;
		    transform: rotate(0deg);
		    border: 0;
		    margin-top: -6px;
		    right: 24px;
		    @screen lg{
		    	width: 10px;
		    	height: 12px;
		    	right: 12px;
		    }
		}
		.list{
			color: #808080;
			font-family: 'Noto Sans TC', 'sans-serif';
    		z-index: -1;
			font-size: 16px;
		    padding-top: 28px;
	        padding-bottom: 10px;
    		top: 36px;
			width: 100%;
			border-radius: 0 0 34px 34px;
		    box-shadow: 0 0 0 1px #888888;
		    @screen lg{
		    	border-radius: 0 0 24px 24px;
		    	top: 16px;
		    	padding-top: 16px;
		    }
			li{
				position: relative;
			    padding: 0px 26px;
			    @screen lg{
			    	padding: 0px 12px;
			    }
				&:after{
					position: absolute;
					content: "";
					width: calc(100% - 18px);
					bottom: 0;
					height: 1px;
					left: 50%;
					transform: translateX(-50%);
					background-color: rgba(#808080, .25);
				}
				&:last-child{
					&:after{
						content: none;
					}
				}
			}
		}
	}
}
.assetList{
	>li{
		&:nth-child(2n-1){
			border-right: 1px solid #80808040;
			@screen lg{
				border: 0;
			}
		}
	}
}
#startList{
	>li{
		&:not(:first-child) {
		    clip-path: polygon(125% 0%, 100% 0%, 100% 100%, 100% 100%);
		}
	}
}
#differentList-pics{
	>li{
	   &:not(:first-child) {
		    clip-path: polygon(125% 0%, 100% 0%, 100% 100%, 100% 100%);
		}
	}
}
.man-pic{
	clip-path: polygon(-5% 0%, 100% 0%, 100% 100%, -20% 100%);
}
@keyframes scroll-y-hint{
	0% {
		left: 10%;
	}
	45.99% {
		left: 80%;
		opacity: 0
	}
	46% {
		left: 0%;
		opacity: 0
	}
	to {
		left: 10%;
		opacity: 1
	}
}
.submit{
	@keyframes viewmore-arrow{
		0% {
			transform: translateX(0%);
		}
		45.99% {
			transform: translateX(125%);
			opacity: 0
		}
		46% {
			transform: translateX(-125%);
			opacity: 0
		}
		to {
			transform: translateX(0%);
			opacity: 1
		}
	}
	
	&:hover{
		.submit-deco{
			g{
				animation: viewmore-arrow .5s;
			}
		}
	}
}
@keyframes go-scroll{
	0% {
		transform: translateY(0%);
	}
	45.99% {
		transform: translateY(125%);
		opacity: 0
	}
	46% {
		transform: translateY(-125%);
		opacity: 0
	}
	to {
		transform: translateY(0%);
		opacity: 1
	}
}
.go-scroll{
	svg{
		animation: go-scroll 1.5s infinite;
	}
	&.corporation{
		@screen lg{
			top: calc(var(--vh, 1vh) * 65);
		}
	}
}
@keyframes go-top{
	0% {
		transform: translateY(0px);
	}
	45.99% {
		transform: translateY(-15px);
		opacity: 0
	}
	46% {
		transform: translateY(15px);
		opacity: 0
	}
	to {
		transform: translateY(0px);
		opacity: 1
	}
}
#backtotop{
	&:hover{
		svg{
			animation: go-top 1.5s infinite;
		}
	}
}
@keyframes go-back{
	0% {
		transform: translateX(0px);
	}
	45.99% {
		transform: translateX(-10px);
		opacity: 0
	}
	46% {
		transform: translateX(10px);
		opacity: 0
	}
	to {
		transform: translateX(0px);
		opacity: 1
	}
}
@keyframes go-more{
	0% {
		transform: translateX(0px);
	}
	45.99% {
		transform: translateX(10px);
		opacity: 0
	}
	46% {
		transform: translateX(-10px);
		opacity: 0
	}
	to {
		transform: translateX(0px);
		opacity: 1
	}
}
.go-back{
	cursor: pointer;
	&:hover{
		polygon{
			animation: go-back 1.5s infinite;
		}
	}
}
.go-more{
	cursor: pointer;
	&:hover{
		polygon{
			animation: go-more 1.5s infinite;
		}
		g{
			animation: go-more 1.5s infinite;
		}
	}
}
.latest-prev{
	cursor: pointer;
	&:hover{
		.switch-prev{
			animation: go-back 1.5s infinite;
		}
	}
}
.latest-next{
	cursor: pointer;
	&:hover{
		.switch-next{
			animation: go-more 1.5s infinite;
		}
	}
}
.scroll-y-hint{
	animation: scroll-y-hint 2.5s infinite;
}

// transition: width 1s cubic-bezier(.76,0,.24,1);
//     width: 20vw;

.heads-mobile{
	@screen lg{
		&.is-go{
			top: 73vh;
		}
		top: calc(var(--vh, 1vh) * 73);
	}
}
#classic-start{
	@screen lg{
		height: calc(var(--vh, 1vh) * 100);
	}
}
.pics-mobile{
	@screen lg{
		height: calc(var(--vh, 1vh) * 50);
	}
}
#picsList{
	@screen lg{
		height: calc(var(--vh, 1vh) * 50);
	}
	>div{
		
	}
}
.pics-mask{
	@screen lg{
		height: calc(var(--vh, 1vh) * 50);
		// top: calc(var(--vh, 1vh) * -73);
	}
}
.house{
	@screen lg{
		height: calc(var(--vh, 1vh) * 50);
	}
}
#houseList{
	@screen lg{
		margin-top: calc(var(--vh, 1vh) * 20);
	}
}
#mobile-more{
	margin-top: calc(var(--vh, 1vh) * 22);
}
.home_hgroup{
	@screen lg{
		top: calc(var(--vh, 1vh) * -8);
		transform: none;
		&.is-go{
			top: calc(var(--vh, 1vh) * -1 + 4px);
		}
	}
}