@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Noto+Serif+TC:wght@200;300;400;500;600;700;900&family=Fahkwang:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:wght@100..900&family=Italianno&display=swap');


*{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html,body{
	font-family: 'Noto Sans TC', 'sans-serif';
	-webkit-text-size-adjust: 100%;   // for mobile fontsize
}
body{
	overflow-x: hidden;
}

:focus-visible{
	outline: none;
}


@layer utilities {
	
	@keyframes anim_clip {
		0% {
			clip-path: inset(0% 100% 0% 0%);
		}
		42%, 58% {
			clip-path: inset(0% 0% 0% 0%);
		}
		100% {
			clip-path: inset(0% 0% 0% 100%);
		}
	}
	.animate-clip{
		animation: anim_clip .7s both cubic-bezier(0.4, 0, 0.2, 1);
	}
	.animate-clip-classic{
		animation: anim_clip 1.2s both cubic-bezier(0.4, 0, 0.2, 1);
	}
	.basic-show{
		opacity: 0;
		pointer-events: none;
		&.is-show{
		  opacity: 1;
		  pointer-events: all;
		}
	}
	.basic-hover{
		cursor: pointer;
		transition: all .3s;
		&:hover{opacity: .6;}
	}
	.writing-vertical-lr{
		writing-mode: vertical-lr;
	}
	.writing-vertical{
		writing-mode: vertical-rl;
	}
	.writing-unset{
		writing-mode: unset;
	}
	.img-cover{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.img-contain{
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.img-fill{
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
	.img-scaledown{
		width: 100%;
		height: 100%;
		object-fit: scale-down;
	}
	.tf{
		top: 50%;
		left: 50%;
		--tw-translate-y: -50%;
		--tw-translate-x: -50%;
		transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
	.tf-x{
		left: 50%;
		--tw-translate-x: -50%;
		transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
	.tf-y{
		top: 50%;
		--tw-translate-y: -50%;
		transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
	.tf-x-auto{
		left: auto;
		transform: none;
	}
	.tf-y-auto{
		top: auto;
		transform: none;
	}
	.tf-auto{
		top: 0%;
		left: 0%;
		transform: none;
	}
	.transform-none{
		transform: none;
	}
	.clip-0{
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
	}
	.clip-x{
		clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
	}
	@for $i from 1 through 15 {
		.zoom-#{$i * 10}{
			zoom: $i * 0.1;
		}
		.zoom-#{$i * 10 + 5}{
			zoom: $i * 0.1 + 0.05;
		}
	}
}